import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { OnDestroy } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { SubscriptionService } from '../../../shared/utilities/subscription';
import { Map } from 'immutable';
import { BehaviorSubject } from 'rxjs';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';

export class ColorLabelProviderServiceCustom extends ColorLabelProviderAbstract implements OnDestroy {

  private subscriptionService = new SubscriptionService();

  private key: string;
  private data = Map<string, TreeNode>();

  public constructor(configurationDispatcher: BehaviorSubject<TreeNode>) {
    super();

    if (!isNullOrUndefined(configurationDispatcher)) {
      this.subscriptionService.add('dispatcher', configurationDispatcher.filter(d => !!d).subscribe((configuration: TreeNode) => {
        if (!isNullOrUndefined(configuration)) {
          this.key = configuration.formFieldId;
          /* Iterate over children */
          const count = configuration.children.length;
          for (let i = 0; i < count; i++) {
            const child = configuration.children[i];
            this.data = this.data.set(child.formId, child);
          }
        }
      }));
    }
  }

  public ngOnDestroy() {
    this.subscriptionService.remove();
  }

  public color(element: TreeNode) {
    if (this.key === undefined) {
      return super.color();
    }
    const value = '' + element[this.key];
    if (this.data.has(value)) {
      const colorNode = this.data.get(value);
      if (!isNullOrUndefined(colorNode.color) && colorNode.color !== '') {
        return [colorNode.color];
      }
    }
    return super.color();
  }

  public label(element: TreeNode) {
    if (element === undefined) {
      return super.color();
    }
    const value = '' + element[this.key];
    if (this.data.has(value)) {
      const d = this.data.get(value);
      return d === undefined ? super.color() : this.data.get(value).name;
    }
    return super.color();
  }

  public legend(): any {
    return this.data.map(d => (<ColorLabelProviderLegend> { key: d.formId, label: d.name, color: d.color })).toArray();
  }

}
